<template>
  <div>
    <b-form-group :label="label">
      <flat-pickr
        v-model="valueLocal"
        :placeholder="placeholder"
        :class="[classe, isError && 'form-error']"
        :disabled="disabled"
        :config="
          type == 'data'
            ? {
                altInput: true,
                altFormat: formatAltData('date'),
                dateFormat: 'Y-m-d',
                mode: mode,
                position: position,
                minDate: minDate,
                maxDate: maxDate,
                defaultDate: defaultDate,
                disableMobile: true,
                onOpen: function (selectedDates, dateStr, instance) {
                  instance.setDate(defaultDate);
                }
              }
            : type == 'datahora'
            ? {
                altInput: true,
                altFormat: formatAltData('dateHour'),
                time_24hr: true,
                enableTime: true,
                dateFormat: 'Y-m-d H:i:S',
                enableSeconds: true,
                minuteIncrement: 1,
                mode: mode,
                position: position,
                minDate: minDate,
                maxDate: maxDate,
                defaultDate: defaultDate,
                disableMobile: true,
                onOpen: function (selectedDates, dateStr, instance) {
                  instance.setDate(defaultDate);
                }
              }
            : {
                altInput: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true,
                altFormat: 'H:i',
                disableMobile: true,
                minuteIncrement: 1,
                static: true
              }
        "
        @on-change="type != 'hora' ? updateSelectValue(valueLocal) : ''"
        @input="type == 'hora' || type == 'datahora' ? updateSelectValue(valueLocal) : ''"
      />
      <calendarIcon
        v-if="type == 'data' || type == 'datahora'"
        class="calendar-icon-datepk"
      />
      <timeIcon v-else />
    </b-form-group>
    <p
      class="msgError"
      v-if="isError"
    >
      {{ msgError }}
    </p>
  </div>
</template>

<script>
  import { BFormGroup } from 'bootstrap-vue';
  import flatPickr from 'vue-flatpickr-component';
  import calendarIcon from '@/assets/images/pages/calendar-icon.svg';
  import timeIcon from '@/assets/images/pages/time-icon.svg';

  export default {
    components: {
      BFormGroup,
      flatPickr,
      calendarIcon,
      timeIcon
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      classe: {
        type: String,
        default: ''
      },
      minDate: {
        type: [Date, String],
        default: ''
      },
      maxDate: {
        type: [Date, String],
        default: ''
      },
      defaultDate: {
        type: Array,
        default: []
      },
      mode: {
        type: String,
        default: 'single'
      },
      position: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      value: {
        default: ''
      },
      isError: {
        type: Boolean,
        default: false
      },
      msgError: {
        type: String,
        default: ''
      },
      readonly: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valueLocal: ''
      };
    },
    mounted() {
      if (this.value) {
        this.valueLocal = this.value;
      }
    },
    watch: {
      valueLocal(v) {
        if (v == '') this.$emit('input', v);
      }
    },
    methods: {
      formatAltData(type) {
        let language = localStorage.getItem('language');
        if (type == 'date')
          if (language == 'pt-BR') {
            return 'd/m/Y';
          } else {
            return 'm/d/Y';
          }
        else {
          if (language == 'pt-BR') {
            return 'd/m/Y H:i:S';
          } else {
            return 'm/d/Y H:i:S';
          }
        }
      },
      updateSelectValue: function (value) {
        if (this.valueLocal) {
          this.$emit('input', value);
        }
      }
    }
  };
</script>
<style>
  .form-error ~ .form-control {
    border: 1px solid #d32f2f !important;
  }

  .msgError {
    margin-top: -9px;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #d32f2f;
    text-align: left;
  }
</style>
