<template>
  <div id="container-scheduling-session-new">
    <b-card id="card-scheduling-session-new">
      <b-col class="p-0">
        <div class="container-card-header">
          <span class="title">
            {{
              !editingContext.editingFlag
                ? $t('IntegrationPage.NewScheduling')
                : $t('IntegrationPage.EditScheduling')
            }}
          </span>

          <div
            class="container-buttons"
            :style="this.allSchedulesList.length == 0 ? 'display:none;' : ''"
          >
            <b-button
              class="btn-scheduling btn-list"
              @click="showLinkedSchedules"
            >
              <span>
                {{ $t('IntegrationPage.LinkedSchedules') }}
              </span>
            </b-button>
          </div>
        </div>

        <b-col
          cols="12"
          class="p-0"
        >
          <b-row>
            <b-form-group
              class="col-12 col-sm-6 col-md-4"
              :label="$t('IntegrationPage.NameField')"
              :invalid-feedback="flagNameEqual ? $t('NameAlreadyExists') : $t('RequiredField')"
            >
              <b-form-input
                :placeholder="$t('TypeHere')"
                v-model="schedulingName"
                :state="flagNameValid"
                id="input-name-scheduling"
              />
            </b-form-group>

            <FormPicker
              :label="$t('IntegrationPage.StartDate')"
              class="col-12 col-sm-6 col-md-4 datepicker-scheduling-pip"
              :type="'datahora'"
              v-model="startDate"
              :minDate="''"
              :maxDate="''"
              :defaultDate="[]"
              :position="'below'"
              :placeholder="'00/00/0000 00:00:00'"
              :msgError="$t('RequiredField')"
              :isError="flagStartDateValid == null ? false : true"
              :key="`calendar-sch-${controlRender}`"
            />

            <FormInputSelect
              :label="$t('IntegrationPage.RecurrenceEvery')"
              :class="
                recurrenceSelected && recurrenceSelected.value == 4
                  ? 'col-12 col-sm-6 col-md-4'
                  : 'col-12 col-md-4'
              "
              :classInput="
                recurrenceSelected && recurrenceSelected.value == 4
                  ? 'col-6 col-sm-5 col-md-4 col-lg-6 col-xl-7'
                  : 'col-6 col-sm-8 col-md-4 col-lg-6 col-xl-7'
              "
              :classSelect="
                recurrenceSelected && recurrenceSelected.value == 4
                  ? 'col-6 col-sm-7 col-md-8 col-lg-6 col-xl-5'
                  : 'col-6 col-sm-4 col-md-8 col-lg-6 col-xl-5'
              "
              :selectValue.sync="recurrenceSelected"
              :inputValue.sync="recurrenceInput"
              :selectOptions="setRecurrenceOptions()"
              :typeInput="'number'"
              :stateInvalidInput="flagRecurrenceInputValid"
              :stateInvalidSelect="flagRecurrenceSelectValid"
              :flagRecurrenceVoidValid="flagRecurrenceVoidValid"
              :flagRecurrenceNumberValid="flagRecurrenceNumberValid"
            />
            <VSelectChips
              key="select-chips-pipeline-schedules"
              :label="$t('IntegrationPage.AdvancedOptions')"
              :labelFor="'optionsAdvanced'"
              class="col-12 col-sm-6 col-md-12 v-select-new-scheduling"
              :placeholder="$t('WorkcenterList.Select')"
              :valueSelectMultiple="selectedOptions"
              :options="setAdvancedOptions()"
              :stateField="null"
              v-show="recurrenceSelected && recurrenceSelected.value == 4"
              @updateData="(value) => (selectedOptions = value)"
            />
          </b-row>
        </b-col>
      </b-col>
    </b-card>
  </div>
</template>

<script lang="js">
  import Ripple from 'vue-ripple-directive';
  import CloseIcon from '@/assets/images/icons/close-icon-small.svg';
  import DeleteIcon from '@/assets/images/icons/delete-icon-small.svg';
  import EditIcon from '@/assets/images/icons/edit-icon-small.svg';
  import DeleteDropdownIcon from '@/assets/images/icons/trash-icon-2.svg';
  import EditDropdownIcon from '@/assets/images/pages/edit-icon-grey.svg';
  import FormPicker from '@/@core/components/form-picker/FormPicker.vue';
  import FormInputSelect from '@/@core/components/form-input-select/FormInputSelect.vue';
  import VSelectChips from '@/@core/components/v-select/vSelectChips.vue';
  import { mapGetters, mapMutations } from 'vuex';
  import {
    BRow,
    BFormGroup,
    BFormInput,
    BCard,
    VBToggle,
    BButton,
    BDropdown,
    BDropdownItem,
    BCol
  } from 'bootstrap-vue';

  export default {
    components: {
      BRow,
      BFormGroup,
      BFormInput,
      BCard,
      BButton,
      BDropdown,
      BDropdownItem,
      BCol,
      CloseIcon,
      DeleteIcon,
      EditIcon,
      DeleteDropdownIcon,
      EditDropdownIcon,
      FormPicker,
      VSelectChips,
      FormInputSelect
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        schedulingID: '',
        startDate: '',
        schedulingName: '',
        recurrenceInput: 1,
        recurrenceSelected: this.setRecurrenceOptions().find((item) => item.value == 2),
        selectedOptions: [],
        oldSchedulingID: '',
        flagNameEqual: false,
        flagNameValid: null,
        flagStartDateValid: null,
        flagRecurrenceInputValid: null,
        flagRecurrenceSelectValid: null,
        flagRecurrenceNumberValid: null,
        flagRecurrenceVoidValid: null,
        controlRender: 0,
        controlRenderAdvanced: 0,
        language: null
      };
    },
    computed: {
      ...mapGetters('flags_controller_pipelines', [
        'flagFloatSaveButton',
        'flagNewSchedulesIbox',
        'flagSchedulesButton'
      ])
    },
    props: {
      showInfos: {
        type: Function
      },
      editingContext: {
        type: Object,
        default: null
      },
      allSchedulesList: {
        type: Array,
        default: []
      },
      flagNewScheduling: {
        type: Boolean
      }
    },
    watch: {
      flagFloatSaveButton(v) {
        if (v) {
          if (
            this.flagNewSchedulesIbox &&
            this.schedulingName == '' &&
            this.startDate == '' &&
            this.recurrenceInput == 1 &&
            this.recurrenceSelected?.value == 2
          )
            this.UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION(true);
        }
      },
      'editingContext.editingFlag'(v) {
        if (v) {
          let editingData = this.setEditingData();
          this.oldSchedulingID = editingData.event;
        }
      },
      flagSchedulesButton(v) {
        if (v) this.addAndEditScheduling(!this.editingContext.editingFlag ? 'add' : 'edit');
      },
      recurrenceSelected(v) {
        this.selectedOptions = v != 4 ? [] : this.selectedOptions;

        v != 4 && this.controlRenderAdvanced++;
      },
      language(v) {
        if (v) this.changeLanguageRecurrenceOptions();
      }
    },
    methods: {
      ...mapMutations('flags_controller_pipelines', [
        'UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION',
        'UPDATE_FLAG_SCHEDULES_BUTTON'
      ]),
      showLinkedSchedules() {
        this.cleanFields();
        this.showInfos('Schedules');
        this.$emit('disableEditing');
        this.$emit('validateTabs', false);
      },
      renameSelectedTags(options) {
        this.selectedOptions.forEach((item) => {
          item.label = options.find((item2) => item2.value == item.value).label;
        });
      },
      setRecurrenceOptions() {
        this.language = localStorage.getItem('language');

        let optionsList = [
          { value: 0, text: this.$t('IntegrationPage.Seconds') },
          { value: 1, text: this.$t('IntegrationPage.Minutes') },
          { value: 2, text: this.$t('IntegrationPage.Hours') },
          { value: 3, text: this.$t('IntegrationPage.Days2') },
          { value: 4, text: this.$t('IntegrationPage.Weeks') },
          { value: 5, text: this.$t('IntegrationPage.Months') },
          { value: 6, text: this.$t('IntegrationPage.Years') }
        ];

        return optionsList;
      },
      changeLanguageRecurrenceOptions() {
        if (this.recurrenceSelected)
          this.recurrenceSelected = this.setRecurrenceOptions().find(
            (item) => item.value == this.recurrenceSelected.value
          );
      },
      setAdvancedOptions() {
        let optionsList = [
          { label: this.$t('IntegrationPage.Monday'), value: 'monday' },
          { value: 'tuesday', label: this.$t('IntegrationPage.Tuesday') },
          { value: 'wednesday', label: this.$t('IntegrationPage.Wednesday') },
          { value: 'thursday', label: this.$t('IntegrationPage.Thursday') },
          { value: 'friday', label: this.$t('IntegrationPage.Friday') },
          { value: 'saturday', label: this.$t('IntegrationPage.Saturday') },
          { value: 'sunday', label: this.$t('IntegrationPage.Sunday') }
        ];

        this.renameSelectedTags(optionsList);

        return optionsList;
      },
      setEditingData() {
        let editingData = this.allSchedulesList.find(
          (item) => item.id === this.editingContext.editingID
        );
        this.schedulingID = editingData.id;
        this.startDate = editingData.startDate;
        this.schedulingName = editingData.event;
        this.recurrenceInput = editingData.recurrenceNumber;
        this.recurrenceSelected = this.setRecurrenceOptions().find(
          (item) => item.value == editingData.recurrencePeriod
        );
        this.controlRender++;

        setTimeout(() => {
          this.selectedOptions = this.setAdvancedOptions().filter((item) =>
            editingData.days.includes(item.value) ? item : ''
          );
        }, 500);

        return editingData;
      },
      cleanFields() {
        this.startDate = '';
        this.schedulingName = '';
        this.recurrenceInput = 1;
        this.recurrenceSelected = this.setRecurrenceOptions().find((item) => item.value == 2);
        this.selectedOptions = [];
        this.schedulingID = '';
        this.flagNameEqual = false;
        this.flagNameValid = null;
        this.flagStartDateValid = null;
        this.flagRecurrenceInputValid = null;
        this.flagRecurrenceSelectValid = null;
        this.flagRecurrenceNumberValid = null;
        this.flagRecurrenceVoidValid = null;
        this.UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION(null);
        this.$emit('changeStatusEdit');

        this.controlRender++;
      },
      createID() {
        let id = `sch-${parseInt(Math.random() * this.allSchedulesList.length * 10)}`;

        if (this.allSchedulesList.find((item) => item.id == id) != undefined) {
          this.createID();
        }

        return id;
      },
      addAndEditScheduling(type) {
        this.UPDATE_FLAG_SCHEDULES_BUTTON(false);
        let validation = this.validateFields();

        if (validation) {
          let scheduling = {
            id: this.schedulingID ? this.schedulingID : this.createID(),
            event: this.schedulingName,
            startDate: this.startDate,
            recurrenceNumber: this.recurrenceInput,
            recurrencePeriod: this.recurrenceSelected?.value,
            days:
              this.selectedOptions?.length > 0
                ? this.selectedOptions?.map((item) => item.value)
                : []
          };

          if (type === 'add') {
            this.$emit('setNewSchedulingData', scheduling);
          } else if (type === 'edit') {
            this.$emit('alterSchedulingData', scheduling);
          }

          this.cleanFields();
          this.showLinkedSchedules();
        }
      },
      validateFields() {
        let minNumberRecurrence = 1;
        let maxNumberRecurrence = 500;
        let recurrenceInput = Number(this.recurrenceInput);

        let nameEqual;

        if (!this.editingContext.editingFlag) {
          nameEqual = this.allSchedulesList.find((item) => item.event == this.schedulingName);
          this.flagNameEqual = nameEqual && this.schedulingName != '' ? true : false;
        } else {
          nameEqual = this.allSchedulesList.find((item) => item.event == this.schedulingName);
          this.flagNameEqual =
            nameEqual && this.schedulingName != '' && this.schedulingName != this.oldSchedulingID
              ? true
              : false;
        }

        this.flagNameValid =
          this.schedulingName != '' && this.flagNameEqual == false ? null : false;
        this.flagStartDateValid = this.startDate != '' ? null : false;
        this.flagRecurrenceVoidValid = this.recurrenceInput != '' ? null : false;
        this.flagRecurrenceNumberValid =
          this.recurrenceInput != '0' &&
          maxNumberRecurrence >= recurrenceInput >= minNumberRecurrence
            ? null
            : false;
        this.flagRecurrenceInputValid =
          recurrenceInput && maxNumberRecurrence >= recurrenceInput >= minNumberRecurrence
            ? null
            : false;
        this.flagRecurrenceSelectValid = this.recurrenceSelected != null ? null : false;

        let validate =
          this.flagNameValid == false ||
          this.flagStartDateValid == false ||
          this.flagRecurrenceInputValid == false ||
          this.flagRecurrenceSelectValid == false
            ? false
            : true;

        if (
          this.schedulingName == '' &&
          this.startDate == '' &&
          this.recurrenceInput == 1 &&
          this.recurrenceSelected?.value == 2
        ) {
          this.showLinkedSchedules();
          return;
        }
        if (validate) {
          this.UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION(null);
        } else {
          this.UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION(validate);
        }

        return validate;
      }
    }
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  // caso altere, busque todas as classes no projeto com este nome e deixe idênticas
  .flatpickr-calendar {
    .flatpickr-day {
      &.startRange,
      &.endRange {
        background-color: #974900 !important;
        border-color: #974900 !important;
        color: #ffff !important;
      }
      &.inRange {
        box-shadow:
          -5px 0 0 #ece0db,
          5px 0 0 #ece0db;
        background-color: #ece0db !important;
        border-color: #ece0db !important;
        color: #6e6b7b !important;
        &:hover {
          box-shadow:
            -5px 0 0 #ece0db,
            5px 0 0 #ece0db;
        }
      }

      &.today {
        border-color: #974900;
        color: #fff;
        background-color: #974900 !important;
      }

      &.today:hover {
        border-color: #974900;
        color: #fff;
      }

      &.selected {
        border-color: #974900;
        color: #fff;
        background-color: #974900 !important;
      }

      &.selected:hover {
        border-color: #974900;
      }
    }
  }

  #card-scheduling-session-new {
    overflow: visible;

    .vs__search {
      border: 1px solid transparent !important;
    }

    input::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #cfc4be;
    }

    label,
    legend {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0 !important;
      padding-bottom: 4px !important;
    }

    .invalid-feedback {
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      color: #d32f2f;
      text-align: left;
    }

    .card-body {
      padding: 16px;
    }

    .line-block {
      background-color: #d9d9d9;
      height: 1px;
      width: 100%;
    }

    .container-card-header {
      flex-direction: row;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4c4541;
      }

      .title-list {
        margin: 16px 0;
      }

      .container-buttons {
        display: flex;

        .btn-scheduling {
          display: flex;
          align-items: center;
          padding: 0 !important;
          border-radius: 5px;
          border: none;
          margin-left: 16px;

          span {
            width: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
            letter-spacing: 0.0125em;
            padding: 4px 14px;
          }

          &:hover {
            box-shadow: none;
          }
        }

        .btn-list {
          background: #fff !important;
          border: 1px solid #974900 !important;

          span {
            color: #974900;
          }

          &:hover {
            box-shadow: none;
            background: #ffede2 !important;
          }

          &:active {
            background: #ffdbc4 !important;
          }
        }
      }
    }

    .datepicker-scheduling-pip {
      input {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        background: #fff;
        padding-left: 40px;
      }

      .calendar-icon-datepk {
        position: absolute;
        top: 32px;
        left: 29px;

        path {
          fill: #cfc4be;
        }
      }
    }
  }

  @media (max-width: 480px) {
    #card-scheduling-session-new {
      .container-card-header {
        flex-direction: column;
        .title {
          align-self: flex-start;
        }
        .container-buttons {
          flex-direction: column;
          width: 100%;
          margin-top: 16px;
          .btn-scheduling {
            width: 100%;
            margin-left: 0;
          }

          .btn-list {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
</style>
